<template>
    <v-container>
    <!-- Added notification for external forms when there are offline data available  -->
    <div v-if="isExternal && offlineDataAvailble">
        <v-card color="info">
            <v-toolbar flat color="info">
                <v-toolbar-title>Sync Message</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
                <v-banner single-line>
                You have some offline form submissions. Click on the sync button to sync them to the server.
                <template v-slot:actions>
                    <v-btn small color="success" @click="resyncExternalForms"><v-icon>mdi-cloud-sync</v-icon></v-btn>
                    <v-btn small color="error" @click="cancelResync"><v-icon>mdi-close</v-icon></v-btn>
                </template>
                </v-banner>
        </v-card>
        <br/>
    </div>
        <v-container v-else>
        <!--- end of external notification -->
            <v-row>
                <v-col sm="12" class="mb-0 pb-0">
                    <h1 class="title font-weight-light">
                        <v-icon v-if="this.preview" @click="onBack">mdi-arrow-left-circle</v-icon>
                        {{ this.item.Name }}&nbsp;<span class="font-weight-light subtitle-1" v-html="this.preview ? '<b class=accent--text>PREVIEW</b>. No answers will be saved' : ''"></span>
                        <span class="caption ml-2">v.{{item.Version}}</span>
                    </h1>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="12" class="mt-0 pt-0 w-1024">
                    <v-card-actions class="ma-0 pa-0">
                        <span class="font-weight-light f-m">{{ user.FullName }}</span>
                        <v-spacer></v-spacer>
                        <span class="font-weight-light f-s">{{ $format.dateTime(new Date()) }}</span>
                    </v-card-actions>
                </v-col>
            </v-row>
            <v-row class="mt-0">
                <v-col sm="12">
                    <v-card :loading="isBusy" class="w-1024">
                        <v-container>
                            <v-progress-linear :value="(index / item.Questions.length) * 100" :active="true" absolute top color="accent"></v-progress-linear>
                            <div class="d-flex"><v-spacer></v-spacer><span class="caption">{{ index === $CONST.END_QUESTION ? item.Questions.length : index }} / {{ item.Questions.length }}</span></div>
                            <!-- Add Previous button to the top as well for simple way to go back-->
                            <v-card-actions v-if="!isSaveDone">
                                <v-btn v-if="!isSaving" text :disabled="item.ShowStart ? index === 0 : index === 1" @click="onPrevious"><v-icon>mdi-chevron-left</v-icon>Previous</v-btn>
                            </v-card-actions>
                            <!------------------- -->
                            <v-card-text style="min-height:300px;">
                                <div class="ck-content" v-if="item.ShowStart && index === 0" v-html="item.StartMessage"></div>
                                <div class="ck-content" v-if="item.ShowEnd && isEnd" v-html="item.EndMessage"></div>
                                <div class="ck-content" v-if="index !== 0 && index !== $CONST.END_QUESTION" v-html="questionText"></div>
                                <div>
                                    <!-- Text -->
                                    <v-text-field
                                        v-if="showInputType($CONST.INPUT_TYPE.Text) && (question.Lines || 1) < 2"
                                        v-model="question.Answer[loopCounter || 0]"
                                        :counter="question.Max || false"
                                        :minlength="question.Min || false"
                                        :maxlength="question.Max || false"
                                        :prefix="question.Pre"
                                        :suffix="question.Post"
                                        :placeholder="question.InHint"
                                        :hint="question.OutHint"
                                        @input="formatValidation(question._id,question.Format)"
                                        @keydown.enter.prevent="onNext"
                                        @paste="formatValidation(question._id,question.Format)"
                                        persistent-hint>
                                    </v-text-field>
                                    <v-textarea
                                        v-else-if="showInputType($CONST.INPUT_TYPE.Text) && (question.Lines || 1) > 1"
                                        v-model="question.Answer[loopCounter || 0]"
                                        :rows="question.Lines || 2"
                                        :counter="question.Max || false"
                                        :minlength="question.Min || false"
                                        :maxlength="question.Max || false"
                                        :prefix="question.Pre"
                                        :suffix="question.Post"
                                        :placeholder="question.InHint"
                                        :hint="question.OutHint"
                                        persistent-hint>
                                    </v-textarea>
                                    <!-- Number -->
                                    <div v-else-if="showInputType($CONST.INPUT_TYPE.Number)">
                                        <v-numeric
                                            v-model="question.Answer[loopCounter || 0]"
                                            type="number"
                                            :maxlength="11"
                                            :numberType="question.NumType"
                                            :prefix="question.Pre"
                                            :suffix="question.Post"
                                            :placeholder="question.InHint"
                                            :hint="question.OutHint"
                                            @enter="onNext"
                                            persistent-hint>
                                        </v-numeric>
                                        <span class="caption ml-2 mt-0" v-if="!isBlank(question.Min) || !isBlank(question.Max)">{{ !isBlank(question.Min) && !isBlank(question.Max) ? `Must be between ${question.Min} and ${question.Max}` : (isBlank(question.Min) ? `Must not be greater than ${question.Max}` : `Must not be less than ${question.Min}`)}}</span>
                                    </div>
                                    <!-- Mobile -->
                                    <v-text-field
                                        v-else-if="showInputType($CONST.INPUT_TYPE.Mobile)"
                                        v-model="question.Answer[loopCounter || 0]"
                                        v-mask="mobileMask(question.Answer[loopCounter || 0])"
                                        :prefix="question.Pre"
                                        :suffix="question.Post"
                                        :placeholder="question.InHint"
                                        :hint="question.OutHint"
                                        :rules="[rules.mobile]"
                                        @keydown.enter.prevent="onNext"
                                        prepend-icon="mdi-cellphone"
                                        persistent-hint>
                                    </v-text-field>
                                    <!-- <v-mask
                                        v-else-if="showInputType($CONST.INPUT_TYPE.Mobile)"
                                        v-model="question.Answer[loopCounter || 0]"
                                        v-mask="'###-###-####'"
                                        :prefix="question.Pre"
                                        :suffix="question.Post"
                                        :placeholder="question.InHint"
                                        :hint="question.OutHint"
                                        @keydown.enter.prevent="onNext"
                                        prepend-icon="mdi-cellphone"
                                        persistent-hint>
                                    </v-mask> -->
                                    <!-- Email -->
                                    <v-text-field
                                        v-else-if="showInputType($CONST.INPUT_TYPE.Email)"
                                        v-model="question.Answer[loopCounter || 0]"
                                        :counter="question.Max || false"
                                        :minlength="question.Min || false"
                                        :maxlength="question.Max || false"
                                        :prefix="question.Pre"
                                        :suffix="question.Post"
                                        :placeholder="question.InHint"
                                        :hint="question.OutHint"
                                        :rules="[rules.email]"
                                        @keydown.enter.prevent="onNext"
                                        prepend-icon="mdi-email-open-outline"
                                        persistent-hint>
                                    </v-text-field>
                                    <!-- WebAddress -->
                                    <v-text-field
                                        v-else-if="showInputType($CONST.INPUT_TYPE.WebAddress)"
                                        v-model="question.Answer[loopCounter || 0]"
                                        :counter="question.Max || false"
                                        :minlength="question.Min || false"
                                        :maxlength="question.Max || false"
                                        :prefix="question.Pre"
                                        :suffix="question.Post"
                                        :placeholder="question.InHint"
                                        :hint="question.OutHint"
                                        :rules="[rules.web]"
                                        @keydown.enter.prevent="onNext"
                                        prepend-icon="mdi-web"
                                        persistent-hint>
                                    </v-text-field>
                                    <!-- YesNo -->
                                    <v-btn-toggle
                                        v-else-if="showInputType($CONST.INPUT_TYPE.YesNo)"
                                        v-model="question.Answer[loopCounter || 0]"
                                        color="accent"
                                        group
                                        tile>
                                        <v-btn :value="true" class="pl-11 pr-11">
                                            Yes
                                        </v-btn>
                                        <v-btn :value="false" class="pl-12 pr-12">
                                            No
                                        </v-btn>
                                    </v-btn-toggle>
                                    <!-- Select One DD -->
                                    <div v-else-if="showInputType($CONST.INPUT_TYPE.SelectOneDD)">
                                        <!-- Filter -->
                                        <v-text-field v-show="question.Filter" v-model="filterOne" class="w-512-max" dense single-line hint="Type to search and filter the available options" prepend-icon="mdi-magnify" persistent-hint></v-text-field>
                                        <span v-show="question.Filter" class="caption opa-6 ml-8">A maximum result of 20 options will be shown.</span>
                                        <!-- Options -->
                                        <v-select v-model="question.Answer[loopCounter || 0]"
                                                :items="optionsOne"
                                                label="Select Option"
                                                item-text="Value"
                                                item-value="Value"
                                                persistent-hint
                                                return-object
                                                single-line
                                                @change="updateSelect">
                                        </v-select>
                                        <span class="caption">{{ question.OutHint }}</span>
                                    </div>
                                    <!-- Select One -->
                                    <div v-else-if="showInputType($CONST.INPUT_TYPE.SelectOne)">
                                        <!-- Filter -->
                                        <v-text-field v-show="question.Filter" v-model="filterOne" class="w-512-max" dense single-line hint="Type to search and filter the available options" prepend-icon="mdi-magnify" persistent-hint></v-text-field>
                                        <span v-show="question.Filter" class="caption opa-6 ml-8 mt-0">A maximum result of 20 options will be shown.</span>
                                        <!-- Options -->
                                        <v-radio-group v-model="question.Answer[loopCounter || 0]">
                                            <v-radio
                                                v-for="item in optionsOne"
                                                :key="item._id"
                                                :label="item.Value"
                                                :value="item.Value">
                                            </v-radio>
                                        </v-radio-group>
                                        <span class="caption">{{ question.OutHint }}</span>
                                    </div>
                                    <!-- Select Many -->
                                    <div v-else-if="showInputType($CONST.INPUT_TYPE.SelectMany)">
                                        <!-- Filter -->
                                        <v-text-field v-show="question.Filter" v-model="filterMany" class="w-512-max" dense single-line hint="Type to search and filter the available options" prepend-icon="mdi-magnify" persistent-hint @keyup.native="filterManyChange"></v-text-field>
                                        <span v-show="question.Filter" class="caption opa-6 ml-8">A maximum result of 20 options will be shown.</span>
                                        <!-- Options -->
                                        <v-checkbox
                                            v-model="question.Answer[loopCounter || 0]"
                                            v-for="item in optionsMany"
                                            :key="item._id"
                                            :label="item.Value"
                                            :value="item.Value"
                                            class="mt-1"
                                            multiple
                                            hide-details>
                                        </v-checkbox>
                                        <span class="caption mt-6 d-block">{{ question.OutHint }}</span>
                                    </div>
                                    <!-- Date -->
                                    <v-menu
                                        v-else-if="showInputType($CONST.INPUT_TYPE.Date)"
                                        v-model="question.ModalDate"
                                        :close-on-content-click="false"
                                        :nudge-right="32"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                v-model="question.Answer[loopCounter || 0]"
                                                :placeholder="question.InHint"
                                                :hint="question.OutHint"
                                                @keydown.enter.prevent="onNext"
                                                prepend-icon="mdi-calendar"
                                                persistent-hint
                                                readonly
                                                v-on="on">
                                            </v-text-field>
                                        </template>
                                        <v-date-picker v-model="question.Answer[loopCounter || 0]" @input="question.ModalDate = false"></v-date-picker>
                                    </v-menu>
                                    <!-- Time -->
                                    <v-menu
                                        v-else-if="showInputType($CONST.INPUT_TYPE.Time)"
                                        ref="menu"
                                        v-model="modalTime"
                                        :close-on-content-click="false"
                                        :nudge-right="32"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                v-model="question.Answer[loopCounter || 0]"
                                                :placeholder="question.InHint"
                                                :hint="question.OutHint"
                                                @keydown.enter.prevent="onNext"
                                                prepend-icon="mdi-clock-outline"
                                                format="24hr"
                                                scrollable
                                                persistent-hint
                                                readonly
                                                v-on="on">
                                            </v-text-field>
                                        </template>
                                        <v-time-picker v-model="question.Answer[loopCounter || 0]" @click:minute="modalTime = false"></v-time-picker>
                                    </v-menu>
                                    <!-- DOB -->
                                    <v-menu
                                        v-else-if="showInputType($CONST.INPUT_TYPE.DateOfBirth)"
                                        v-model="modalDOB"
                                        :close-on-content-click="false"
                                        :nudge-right="32"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                v-model="question.Answer[loopCounter || 0]"
                                                :placeholder="question.InHint"
                                                :hint="question.OutHint"
                                                @keydown.enter.prevent="onNext"
                                                prepend-icon="mdi-gift-outline"
                                                persistent-hint
                                                readonly
                                                v-on="on">
                                            </v-text-field>
                                        </template>
                                        <v-date-picker ref="previewDOB" v-model="question.Answer[loopCounter || 0]" @input="modalDOB = false" :max="new Date().toISOString().substr(0, 10)"></v-date-picker>
                                    </v-menu>
                                    <!-- Rating -->
                                    <div v-else-if="showInputType($CONST.INPUT_TYPE.Rating)">
                                        <v-rating
                                            v-model="ratingVal"
                                            :length="question.Max || 5"
                                            color="accent"
                                            background-color="grey lighten-1">
                                        </v-rating>
                                        <span class="caption">{{ question.OutHint }}</span>
                                    </div>
                                    <!-- Slider -->
                                    <div v-else-if="showInputType($CONST.INPUT_TYPE.RangeSlider)">
                                        <v-row class="row-smaller mt-7">
                                            <v-col sm="12" class="d-flex">
                                                <span class="body-1 mr-2" v-show="question.Pre">{{ question.Pre }}</span>
                                                <v-slider
                                                    v-model="question.Answer[loopCounter || 0]"
                                                    :thumb-size="24"
                                                    :min="question.Min || 0"
                                                    :max="question.Max || 10"
                                                    :step="question.Step || 1"
                                                    :hint="question.OutHint"
                                                    thumb-label="always"
                                                    ticks="always"
                                                    tick-size="4"
                                                    color="accent"
                                                    persistent-hint>
                                                </v-slider>
                                                <span class="body-1 ml-2" v-show="question.Post">{{ question.Post }}</span>
                                            </v-col>
                                        </v-row>
                                        <v-row class="row-smaller">
                                            <v-col sm="12" class="text-center display-1 accent--text" align-self="center">
                                                <span>{{ question.Answer[loopCounter || 0] }}</span>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <!-- Image -->
                                    <div v-else-if="showInputType($CONST.INPUT_TYPE.Image)">
                                        <v-btn color="primary" class="mr-2" @click="onTakePicture">
                                            <v-icon left>mdi-camera</v-icon>
                                            Take Picture
                                        </v-btn>
                                        <v-btn color="primary" v-if="question.Local" @click="onFileLoadPhotoClick">
                                            <v-icon left>mdi-folder-image</v-icon>
                                            <span class="hide-sx">Load</span>
                                        </v-btn>
                                        <v-file-input ref="fileLoadPhoto" v-model="question.ValueFile" accept="image/*" label="Load Image" class="d-none" @change="onFileLoadChange"></v-file-input>
                                        <br/>
                                        <v-img :src="question.Answer[loopCounter || 0]" class="mt-2"></v-img><!-- https://picsum.photos/510/300?random -->
                                        <span class="caption">{{ question.OutHint }}</span>
                                    </div>
                                    <!-- Sketch Image -->
                                    <div v-else-if="showInputType($CONST.INPUT_TYPE.Sketch)">
                                        <div v-if="!question.DefaultSketchBackground">
                                            <v-btn
                                                color="primary"
                                                class="mr-2"
                                                @click="onSketchImage">
                                                <v-icon left>
                                                    mdi-camera
                                                </v-icon>
                                                Take Picture
                                            </v-btn>
                                            <br/>
                                            <div v-if="sketchImageSrc">
                                                <SketchImage
                                                    :defaultImage="question.DefaultSketchBackground"
                                                    :image="question.Answer[loopCounter || 0]"
                                                    @image-data="newImageData">
                                                </SketchImage>
                                                <span class="caption">
                                                {{ question.OutHint }}
                                                </span>
                                            </div>
                                        </div>
                                        <div v-if="question.DefaultSketchBackground">
                                            <SketchImage
                                                :defaultImage="question.DefaultSketchBackground"
                                                :image="sketchImageSrc"
                                                @image-data="newImageData">
                                            </SketchImage>
                                        </div>
                                    </div>
                                    <!-- DynamicLookup -->
                                    <div v-else-if="showInputType($CONST.INPUT_TYPE.DynamicLookup)">
                                        <DynamicLookup
                                            :items="selectedDynamicLookupSourceItems"
                                            :selectedItem="question.DynamicLookupOptions.SelectedSource"
                                            :selectedType="selectedDynamicLookupType"
                                            :disableFilter="!question.Filter"
                                            :selectedValues="selectedDynamicValues"
                                            @selected-value="(selection) => onDynamicLookupSelection(selection)">
                                        </DynamicLookup>
                                    </div>
                                    <!-- UserLookup -->
                                    <div v-else-if="showInputType($CONST.INPUT_TYPE.UserLookup)">
                                        <UserLookup :disableFilter="!question.Filter"
                                                    :items="userLookupUsers"
                                                    @selected-users="(selection) => onUserLookupSelection(selection)">
                                        </UserLookup>
                                    </div>
                                    <!-- Signature -->
                                    <div v-else-if="showInputType($CONST.INPUT_TYPE.Signature)">
                                        <v-icon v-if="!canSignAgain" class="mr-2">mdi-fingerprint</v-icon>
                                        <v-btn  v-if="canSignAgain" color="primary" class="mr-2" @click="onSignAgain">
                                            <v-icon left>mdi-fingerprint</v-icon>
                                            <span class="hide-sx mr-1">Sign</span>Again
                                        </v-btn>
                                        <v-btn v-if="question.Local" color="primary" @click="onFileLoadSignClick">
                                            <v-icon left>mdi-folder-image</v-icon>
                                            <span class="hide-sx">Load</span>
                                        </v-btn>
                                        <v-btn color="primary" class="error ml-2 float-right" @click="onSignClear">
                                            <v-icon text>mdi-delete-forever-outline</v-icon>
                                            <span class="hide-sx">Clear</span>
                                        </v-btn>
                                        <v-file-input ref="fileLoadSign" v-model="question.ValueFile" accept="image/*" label="Load Image" class="d-none" @change="onFileLoadChange"></v-file-input>
                                        <br/>
                                        <canvas v-if="!canSignAgain" id="user-sign" style="width: 100%; height: 300px; border: 10px solid #EEEEEE;"></canvas>
                                        <v-img v-if="canSignAgain" :src="question.Answer[loopCounter || 0]" class="mt-2"></v-img><!-- https://picsum.photos/510/300?random -->
                                        <span class="caption">{{ question.OutHint }}</span>
                                    </div>
                                    <!-- GPS Location -->
                                    <div v-else-if="showInputType($CONST.INPUT_TYPE.GPSLocation)">
                                        <v-btn color="primary" v-show="!isLocationOn" @click="getLocation">
                                            <v-icon left>mdi-map-marker-radius</v-icon>
                                            Get Location
                                        </v-btn>
                                        <v-btn color="warning" v-show="isLocationOn" @click="stopLocation">
                                            <v-icon left>mdi-map-marker-off-outline</v-icon>
                                            Stop
                                        </v-btn>
                                        <br/>
                                        <span class="caption">{{ question.OutHint }}</span>
                                        <v-row class="w-512-max mt-2 row-smaller">
                                            <v-col sm="4">Coordinates</v-col>
                                            <!-- <v-col sm="8" class="font-weight-bold">{{ question.Answer[loopCounter || 0] || '-' }}</v-col> -->
                                            <v-col sm="8" class="font-weight-bold subtitle-1">{{ question.Display }}</v-col>
                                        </v-row>
                                        <v-row class="w-512-max row-smaller">
                                            <v-col sm="4">Latitude</v-col>
                                            <v-col sm="8">{{ question.DisplayLat || '-' }}</v-col>
                                        </v-row>
                                        <v-row class="w-512-max row-smaller">
                                            <v-col sm="4">Longitude</v-col>
                                            <v-col sm="8">{{ question.DisplayLon || '-' }}</v-col>
                                        </v-row>
                                        <v-row class="w-512-max row-smaller">
                                            <v-col sm="4">Accuracy</v-col>
                                            <v-col sm="8" :class="question.DisplayAccFail ? 'error--text' : ''">{{ question.DisplayAcc }}</v-col>
                                        </v-row>
                                        <v-row class="w-512-max row-smaller">
                                            <v-col sm="4">Altitude</v-col>
                                            <v-col sm="8">{{ question.DisplayAlt || '-' }}</v-col>
                                        </v-row>
                                        <v-row class="w-512-max row-smaller">
                                            <v-col sm="4">Measured at</v-col>
                                            <v-col sm="8">{{ question.DisplayTime || '-' }}</v-col>
                                        </v-row>
                                        <v-row class="w-512-max row-smaller">
                                            <v-col sm="4">Attempts</v-col>
                                            <v-col sm="8">{{ question.DisplayAttempts || '-' }}</v-col>
                                        </v-row>
                                        <!-- <v-row class="w-512-max">
                                            <v-col sm="4">Speed</v-col>
                                            <v-col sm="8">{{ question.Meta[loopCounter || 0].ValueSpd || '-' }}</v-col>
                                        </v-row> -->
                                    </div>
                                    <!-- Barcode -->
                                    <div v-else-if="showInputType($CONST.INPUT_TYPE.Barcode)">
                                        <QRScanner
                                            ref="barcodeScanner"
                                            :questionValue="question.Answer[loopCounter || 0]"
                                            @update-value="updateScannerValue"
                                            icon="mdi-qrcode-scan"
                                            :readonly="!question.Manual"
                                            :scanvin="question.ScanVin"
                                            :scanreg="question.ScanReg"
                                            :scanengine="question.ScanEngine"
                                            :prefix="question.Pre"
                                            :suffix="question.Post"
                                            :placeholder="question.InHint"
                                            :hint="question.OutHint"
                                            @next-question="onNext">
                                        </QRScanner>
                                    </div>
                                    <!-- DriverQRScanner -->
                                    <div v-else-if="showInputType($CONST.INPUT_TYPE.DriverQRScanner)">
                                        <DriverQRScanner
                                            ref="driverScanner"
                                            :questionValue="question.Answer[loopCounter || 0]"
                                            @update-value="updateDriverValue"
                                            icon="mdi-qrcode-scan"
                                            @next-question="onNext">
                                        </DriverQRScanner>
                                    </div>
                                    <!-- XorGrid-->
                                    <div v-else-if="showInputType($CONST.INPUT_TYPE.XorGrid)">
                                        <v-xorgrid
                                            @valueChanged="updatedGridPairs"
                                            @validResultChanged="updateGridResult"
                                            :gridData="question.GridData"
                                            :prefix="question.Pre"
                                            :suffix="question.Post"
                                            ref="xorGrid">
                                        </v-xorgrid>
                                    </div>
                                    <!-- FileUpload-->
                                    <div v-else-if="showInputType($CONST.INPUT_TYPE.FileUpload)">
                                        <v-fileupload
                                        :key="question._id"
                                        :parentData.sync="question.ValueFile"
                                        :validState.sync="questionErrors[question.Field]"
                                        :parentImageData.sync="imageData[question.Field]"
                                        :uploadType="question.UploadType"
                                        :uploadSizeLimit="question.UploadSizeLimit"
                                        :hint="question.InHint"
                                        :isOptional="question.Optional">
                                        </v-fileupload>
                                    </div>
                                </div>
                            </v-card-text>
                            <v-alert type="error" v-show="errorDetail">
                                {{ errorDetail }}
                            </v-alert>
                            <v-alert type="error" v-show="answerNotValid">
                                Answer validation failed. Please review your answer.
                            </v-alert>
                            <div v-if="isSaveDone" class="text-right">
                                <v-icon color="success" style="font-size:100px;">mdi-check</v-icon>
                            </div>
                            <v-divider v-if="!isSaveDone"></v-divider>
                            <v-card-actions v-if="!isSaveDone">
                                <v-btn v-if="!isSaving" text :disabled="item.ShowStart ? index === 0 : index === 1" @click="onPrevious">
                                    <v-icon>mdi-chevron-left</v-icon>Previous
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-chip v-if="loopCounter" color="accent" label dark>
                                    <v-icon left>mdi-rotate-3d-variant</v-icon>
                                    {{ loopCounter }}/{{ loopTo }}
                                </v-chip>
                                <v-spacer></v-spacer>
                                <v-chip v-if="item.RatingTotal" label class="mr-2" title="Rating Total">
                                    <v-icon left>mdi-calculator-variant</v-icon>
                                    <span v-if="item.RatingSymbol">{{ item.RatingSymbol }}&nbsp;</span> {{ ratingTotal }}
                                </v-chip>
                               <!-- Scenario 1: No Cover Messages -->
                                <v-btn v-if="isSingleQuestion && !item.ShowStart && !item.ShowEnd"
                                    :disabled="isSaving"
                                    :loading="isSaving"
                                    text
                                    @click="onSave">Finish
                                    <v-icon>
                                        mdi-flag-checkered
                                    </v-icon>
                                </v-btn>

                                <!-- Scenario 2: Only Start Cover Message -->
                                <v-btn v-if="isSingleQuestion && item.ShowStart && !item.ShowEnd && coverMessageIndex === 0"
                                    :disabled="isSaving"
                                    :loading="isSaving"
                                    text
                                    @click="onNext"
                                    data-test="A">Next
                                    <v-icon>
                                        mdi-chevron-right
                                    </v-icon>
                                </v-btn>
                                <v-btn v-if="isSingleQuestion && item.ShowStart && !item.ShowEnd && coverMessageIndex === 1"
                                    :disabled="isSaving"
                                    :loading="isSaving"
                                    text
                                    @click="onSave">Finish
                                    <v-icon>
                                        mdi-flag-checkered
                                    </v-icon>
                                </v-btn>

                                <!-- Scenario 3: Start and End Cover Messages -->
                                <v-btn v-if="isSingleQuestion && item.ShowStart && item.ShowEnd && coverMessageIndex === 0"
                                    :disabled="isSaving"
                                    :loading="isSaving"
                                    text
                                    @click="onNext"
                                    data-test="B">Next
                                    <v-icon>
                                        mdi-chevron-right
                                    </v-icon>
                                </v-btn>
                                <v-btn v-if="isSingleQuestion && item.ShowStart && item.ShowEnd && coverMessageIndex === 1"
                                    :disabled="isSaving"
                                    :loading="isSaving"
                                    text
                                    @click="onNext"
                                    data-test="B2">Next
                                    <v-icon>
                                        mdi-chevron-right
                                    </v-icon>
                                </v-btn>
                                <v-btn v-if="isSingleQuestion && item.ShowStart && item.ShowEnd && coverMessageIndex === 2"
                                    :disabled="isSaving"
                                    :loading="isSaving"
                                    text
                                    @click="onSave">Finish
                                    <v-icon>
                                        mdi-flag-checkered
                                    </v-icon>
                                </v-btn>

                                <!-- Scenario 4: Only End Cover Message -->
                                <v-btn v-if="isSingleQuestion && !item.ShowStart && item.ShowEnd && coverMessageIndex === 0"
                                    :disabled="isSaving"
                                    :loading="isSaving"
                                    text
                                    @click="onNext"
                                    data-test="C">Next
                                    <v-icon>
                                        mdi-chevron-right
                                    </v-icon>
                                </v-btn>
                                <v-btn v-if="isSingleQuestion && !item.ShowStart && item.ShowEnd && coverMessageIndex === 1"
                                    :disabled="isSaving"
                                    :loading="isSaving"
                                    text
                                    @click="onSave">Finish
                                    <v-icon>
                                        mdi-flag-checkered
                                    </v-icon>
                                </v-btn>

                                <!-- If Multiple Questions -->
                                <v-btn v-if="!isSingleQuestion && ((item.ShowStart && !item.ShowEnd && index === 0) || (!item.ShowStart && !item.ShowEnd && index === item.Questions.length - 1))"
                                    :disabled="isSaving"
                                    :loading="isSaving"
                                    text
                                    @click="onNext"
                                    data-test="D">Next
                                    <v-icon>
                                        mdi-chevron-right
                                    </v-icon>
                                </v-btn>
                                <v-btn v-else-if="!isSingleQuestion && (!item.ShowStart && !item.ShowEnd && !checkShowMessage || (item.ShowEnd ? !isEnd : index !== 0 && index !== item.Questions[item.Questions.length - 1].Index && index !== $CONST.END_QUESTION))"
                                    :disabled="isSaving"
                                    :loading="isSaving"
                                    text
                                    @click="onNext"
                                    data-test="D2">Next
                                    <v-icon>
                                        mdi-chevron-right
                                    </v-icon>
                                </v-btn>
                                <v-btn v-if="!isSingleQuestion && (item.ShowEnd ? isEnd : index === $CONST.END_QUESTION || isEnd)"
                                    :disabled="isSaving"
                                    :loading="isSaving"
                                    text
                                    @click="onSave">Finish
                                    <v-icon>
                                        mdi-flag-checkered
                                    </v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-container>
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="mt-0" v-if="this.preview">
                <v-col sm="12">
                    <v-container>
                        <v-btn color="primary" @click="onBack">Back</v-btn>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
import Constants from '@/util/Constants';
import Data from '@/util/Data';
// import ImageTools from '@/util/ImageTools';
import ImageResize from '@/util/ImageResize';
// import mask from '@/controls/Mask';
import numeric from '@/controls/Numeric';
// import { decode } from 'base64-arraybuffer';
import { mapState } from 'vuex';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
// import { Geolocation } from '@capacitor/geolocation';
import SignaturePad from 'signature_pad';
import Ui from '@/util/Ui';
import XorGrid from '@/controls/XorGrid';
import FileUpload from '@/controls/FileUpload';
import QRScanner from '@/controls/QRScanner.vue';
import DriverQRScanner from '@/controls/DriverQRScanner.vue';
import SketchImage from '@/controls/SketchImage.vue';
import DynamicLookup from '@/controls/DynamicLookup.vue';
import UserLookup from '@/controls/UserLookup.vue';
// const imgTools = new ImageTools();

/*
 * NOTE: Here, Answers are stored as arrays. Non-looping question answers are on index 0
 *       while looping question answers are on the relevant loop index.
 *       The values are extracted on save.
 */

export default {
    name: 'Act',
    props: {
        propRenderFinish: {
            default: false,
            type: Boolean
        },
        propRouteOnSave: {
            default: false,
            type: Boolean
        },
        propTaskForm: {
            default: false,
            type: Boolean
        },
        propEmitSave: {
            default: false,
            type: Boolean
        },
        propId: {
            default: '',
            type: String
        },
    },
    components: {
        // 'v-mask': mask,
        'v-numeric': numeric,
        'v-xorgrid': XorGrid,
        'v-fileupload': FileUpload,
        QRScanner,
        DriverQRScanner,
        SketchImage,
        DynamicLookup,
        UserLookup
    },
    mounted () {
        if (this.$route.query) {
            if (this.propId) {
                this.item._id = this.$route.query.id;
                this.loadedId = this.$route.query.id;
            }
            else if (this.$route.query.id) {
                this.item._id = this.$route.query.id;
                this.loadedId = this.$route.query.id;
            }
            if (this.$route.query.instance) {
                const inputInstance = parseInt(this.$route.query.instance);
                if (!isNaN(inputInstance)) {
                    this.instance = inputInstance + 1;
                }
            }
            if (this.$route.query.navcat) {
                const navcatId = this.$route.query.navcat;
                if (navcatId !== undefined) {
                    this.navcatreference = navcatId;
                }
            }
            if (this.$route.query.preview) this.preview = this.$route.query.preview;
            if (this.$route.query.external) {
                this.isExternal = this.$route.query.external;
                // Check if there are any offline submission available and warn if this is the case
            }
        }
        this.loadData();
        this.isApple = Ui.isApple();
    },
    /**
     * This is only called if the view has already been initialised, but shown/used again e.g. /User/1 and /User/2
     */
    beforeRouteUpdate (to, from, next) {
        if (this.$route.query) {
            // Reset previous values.
            this.startDate = new Date();
            this.item = { Name: '...', Questions: [Data.duplicate(Constants.BLANK_QUESTION)] };
            if (to.query.id) this.item._id = to.query.id;
            if (to.query.preview) this.preview = to.query.preview;
            this.stepState = [];
            this.isEnd = false;
            this.answerNotValid = false;
            this.errorDetail = '';
            this.loopCounter = 0;
            this.loopTo = 0;
            this.ratingTotal = 0;
            this.index = -1; // this.item.ShowStart ? 0 : this.item.Questions[0].Index;
            this.question = this.item.Questions[0];
        }
        next();
        this.loadData();
    },
    data: () => ({
        navcatreference: '',
        instance: 1,
        loadedId: '',
        sketchImageSrc: '',
        selectedMarker: false,
        isBusy: false,
        isExternal: false,
        offlineDataAvailble: false,
        offlineProjId: '',
        selectedDynamicLookupSourceItems: [],
        selectedDynamicLookupType: { value: '', text: '' },
        selectedDynamicValues: [],
        lang: 'en',
        item: { Name: '...', Questions: [Data.duplicate(Constants.BLANK_QUESTION)], MetaFields: [] },
        preview: false,
        modalDate: false,
        modalTime: false,
        modalDOB: false,
        question: {
            Question: [{ Value: '' }],
            PassRuleEquation: [],
            ShowRuleEquation: [],
            ShowRules: [],
            PassRules: [],
            RatingRules: [],
            Options: [],
            GridData: [],
            Answer: [],
            Optional: false
        },
        questionText: '',
        loopCounter: 0,
        loopTo: 0,
        answerNotValid: false,
        errorDetail: '',
        canSignAgain: false,
        stepState: [], // Previous question indexes for moving backwards.
        index: -1,
        filterOne: '',
        filterMany: '',
        isEnd: false,
        checkShowMessage: false,
        gridIsValid: false,
        isSaving: false,
        isSaveDone: false,
        startDate: new Date(),
        isLocationOn: false,
        defStartMsg: '',
        defEndMsg: '',
        ratingTotal: 0,
        rules: {
            required: value => !!`${(value || '')}`.length || 'Required.',
            min: value => (value || '').length > 1 || 'Minimum 2 characters.',
            mobile: value => (value || '').length === 12 || 'Invalid phone number.',
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(value) || 'Invalid e-mail.';
            },
            web: value => {
                const pattern = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
                return pattern.test(value) || 'Invalid web address.';
            }
        },
        imageData: {},
        questionErrors: {},
        coverMessageIndex: 0,
        userLookupUsers: []
    }),
    methods: {
        async refresh () {
            await this.$db.refreshDynamicLookupSets();
        },
        mobileMask (val) {
            if (val === '') return val;
            const number = val.replace(/[^0-9]/g, '');
            let maskedNumber = number;
            if (maskedNumber.length > 3 && maskedNumber.length <= 6) maskedNumber = number.replace(/(\d{3})(\d{1})/, '$1-$2');
            else if (maskedNumber.length > 6) maskedNumber = number.replace(/(\d{3})(\d{3})(\d{1})/, '$1-$2-$3');
            return maskedNumber;
        },
        formatValidation (index, format) {
            let val = null;
            switch (format.toLowerCase()) {
                case 'caps':
                    val = `${this.question.Answer[this.loopCounter || 0]}`.toUpperCase();
                    break;
                case 'lower':
                    val = `${this.question.Answer[this.loopCounter || 0]}`.toLowerCase();
                    break;
                case 'capsnospaces':
                    val = `${this.question.Answer[this.loopCounter || 0]}`.toUpperCase().replaceAll(' ', '');
                    break;
                case 'lowernospaces':
                    val = `${this.question.Answer[this.loopCounter || 0]}`.toLowerCase().replaceAll(' ', '');
                    break;
                case 'nospaces':
                    val = `${this.question.Answer[this.loopCounter || 0]}`.replaceAll(' ', '');
                    break;
                default:
                    break;
            }
            if (val !== null) {
                this.question.Answer[this.loopCounter || 0] = val;
            }
        },
        updatedGridPairs (value) {
            this.question.GridData = value;
            this.question.Answer[this.loopCounter || 0] = value;
        },
        updateGridResult (value) {
            this.gridIsValid = value;
        },
        updateSelect (value) {
            this.question.Answer = [value.Value];
        },
        async loadData () {
            if (!this.item._id) {
                this.isBusy = false;
                this.$hideProgress();
                this.$warning('No Questions', 'There are no questions to preview. Please add questions and make sure the form is saved before previewing.');
                this.onBack();
                return;
            }
            this.isBusy = true;
            this.$showProgress();
            try {
                let d;
                // IF TASK FORM THEN GO GET THE PUBLISHED SURVEY STRAIGHT FROM THE SERVER
                if (this.propTaskForm) {
                    const whereClause = {
                        SurveyId: this.item._id
                    };
                    const paging = {
                        size: 1
                    };
                    const sorting = {
                        Version: -1
                    };
                    const results = await this.$http.get('/SurveyPublished', { params: { where: whereClause, paging: paging, sort: sorting } });
                    d = results.data.d.reverse()[0];
                }
                else {
                    if (!this.preview) {
                        d = await this.$db.getPublishedOne(this.item._id);
                    }
                }

                // IF published survey not found / preview, fetch 'survey' from server
                if (d === undefined) {
                    const remoteData = await this.$http.get(`/Survey/${this.item._id}`);
                    d = remoteData.data.d;
                    this.offlineProjId = d.ProjectId;
                    this.$db.countProjectAnswersToPushExternal(d.ProjectId).then(counts => {
                        if (counts.size > 0) this.offlineDataAvailble = true;
                        else this.offlineDataAvailble = false;
                    }
                    );
                }
                this.isValid = true;
                if (!d.Questions.length) {
                    this.isBusy = false;
                    this.$hideProgress();
                    this.$warning('No Questions', 'There are no questions to preview. Please add questions and make sure the form is saved before previewing.');
                    this.onBack();
                }
                Data.sort(d.Questions, 'Index');
                // Check if there are looping questions. Add the parent id to those questions.
                d.Questions.forEach(o => {
                    if (o.LoopParent) {
                        const nums = o.LoopParent.split(/[ ,;]+/);
                        const loopIndexes = [];
                        nums.forEach(num => {
                            if (num.indexOf('-') > -1) {
                                const startEnd = num.split('-');
                                const numStart = +startEnd[0];
                                const numEnd = +startEnd[1];
                                for (let si = numStart; si <= numEnd; si++) {
                                    loopIndexes.push(si);
                                    const q = d.Questions.find(x => x.Index === si);
                                    q.LoopParentIndex = o.Index;
                                }
                            }
                            else {
                                const idx = +num;
                                loopIndexes.push(idx);
                                const q = d.Questions.find(x => x.Index === idx);
                                q.LoopParentIndex = o.Index;
                            }
                        });
                        o.loopStartIndex = loopIndexes[0];
                        o.loopEndIndex = loopIndexes[loopIndexes.length - 1];
                    }
                    else if (o.LoopParent !== undefined) delete o.LoopParent;

                    this.imageData[o.Field] = null;
                    this.questionErrors[o.Field] = false;
                });
                this.defStartMsg = d.StartMessage;
                this.defEndMsg = d.EndMessage;

                // Existing forms wont have these new properties so we need ensure they are defined.
                if (!d.HasWorkflow) {
                    d.HasWorkflow = false;
                    d.WorkflowId = '';
                }
                // add metafields if not available on existing forms
                if (d.MetaFields === undefined) {
                    this.item.MetaFields = [];
                }
                else {
                    this.item.MetaFields = d.MetaFields;
                }
                this.item = d;
                this.index = this.item.ShowStart ? 0 : this.item.Questions[0].Index;
                this.question = this.item.Questions[0];
            }
            catch (ex) {
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
            finally {
                this.isBusy = false;
                this.$hideProgress();
            }
        },
        isBlank (value) {
            if (Array.isArray(value)) {
                // Check if the element has a child array in it
                if (value.length > 0) {
                    if (Array.isArray(value[0])) {
                        return value[0].length === 0;
                    }
                    else {
                        return false;
                    }
                }
                else return (value.length === 0);
            }
            if (value === '[]') {
                return true;
            }
            else return value === '' || value === undefined || value === null;
        },
        showInputType (typeId) {
            if (this.index === 0 || this.index === Constants.END_QUESTION) return 0;
            return typeId === this.question.InputType;
        },
        async onTakePicture () {
            const image = await Camera.getPhoto({
                quality: this.question.Quality,
                allowEditing: false,
                source: CameraSource.Camera,
                resultType: CameraResultType.DataUrl
            });

            const sizer = new ImageResize(this.question.Width, this.question.Height, 1);
            const imgSized = await sizer.resize(image);
            const answer = this.question.Answer;
            answer[this.loopCounter || 0] = imgSized;
            this.question.Answer = this.question.Answer.slice();
        },
        async onSketchImage () {
            const image = await Camera.getPhoto({
                quality: this.question.Quality,
                allowEditing: false,
                source: CameraSource.Camera,
                resultType: CameraResultType.DataUrl
            });
            this.sketchImageSrc = image.dataUrl;
            const answer = this.question.Answer;
            answer[this.loopCounter || 0] = image.dataUrl;
        },
        async newImageData (data) {
            this.selectedMarker = true;
            const sizer = new ImageResize(this.question.Width, this.question.Height, 1);
            const imgSized = await sizer.resize(data);
            const answer = this.question.Answer;
            answer[this.loopCounter || 0] = imgSized;
            this.question.Answer = this.question.Answer.slice();
        },
        onFileLoadPhotoClick () {
            const fu = this.$refs.fileLoadPhoto.$el.querySelectorAll('input');
            fu[0].click();
        },
        initSignPad () {
            this.canvas = document.getElementById('user-sign');
            if (!this.canvas) {
                setTimeout(this.initSignPad.bind(this), 500); // Retry until the canvas is available.
                return;
            }
            this.signaturePad = new SignaturePad(this.canvas, { backgroundColor: 'rgb(255, 255, 255)' });
            // const answer = this.question.Answer;
            const canvas = this.canvas;
            const signaturePad = this.signaturePad;
            this.signaturePad.addEventListener('beginStroke', () => {
                this.answerNotValid = false;
                this.errorDetail = false;
            });
            this.signaturePad.addEventListener('endStroke', () => {
                const answer = this.question.Answer;
                answer[this.loopCounter || 0] = signaturePad.toDataURL('image/jpeg');
            });
            function resizeCanvas () {
                const ratio = Math.max(window.devicePixelRatio || 1, 1);
                canvas.width = canvas.offsetWidth * ratio;
                canvas.height = canvas.offsetHeight * ratio;
                canvas.getContext('2d').scale(ratio, ratio);
                signaturePad.clear(); // otherwise isEmpty() might return incorrect value
            }
            window.addEventListener('resize', resizeCanvas);
            resizeCanvas();
        },
        onSignAgain () {
            this.canSignAgain = false;
            this.initSignPad();
        },
        onSignClear () {
            if (this.canSignAgain) return this.onSignAgain();
            this.signaturePad.clear();
            this.question.Answer[this.loopCounter || 0] = '';
            this.$forceUpdate();
        },
        onFileLoadSignClick () {
            const fu = this.$refs.fileLoadSign.$el.querySelectorAll('input');
            fu[0].click();
        },
        async onFileLoadChange (file) {
            /* const reader = new FileReader();
            reader.onload = e => {
                const answer = this.question.Answer;
                answer[this.loopCounter || 0] = e.target.result;
                this.question.Answer = this.question.Answer.slice();
                // this.$set(this.question, 'Answer', answer); // Use .$set to trigger the change.
            };
            reader.readAsDataURL(file); // Convert to base64 string. */

            /* let imgSized = null;
            if (file.size > 1048576) { // If bigger than 1 MB.
                imgSized = await imgTools.resize(file, { width: this.question.Width, height: this.question.Height }, { mime: 'image/jpeg', quality: 0.9 });

                const answer = this.question.Answer;
                // answer[this.loopCounter || 0] = imgStr;
                answer[this.loopCounter || 0] = imgSized;
                this.question.Answer = this.question.Answer.slice();
            }
            else {
                const reader = new FileReader();
                reader.onload = e => {
                    const answer = this.question.Answer;
                    answer[this.loopCounter || 0] = e.target.result;
                    this.question.Answer = this.question.Answer.slice();
                    // this.$set(this.question, 'Answer', answer); // Use .$set to trigger the change.
                };
                reader.readAsDataURL(file); // Convert to base64 string.
            } */

            const sizer = new ImageResize(this.question.Width, this.question.Height, 0.9);
            const imgSized = await sizer.resize({
                dataUrl: sizer.fileToDataUrl(file),
                format: file.type
            });
            const answer = this.question.Answer;
            answer[this.loopCounter || 0] = imgSized;
            this.question.Answer = this.question.Answer.slice();
        },
        getLocation () {
            const q = this.question;
            this.$set(q, 'Display', 'Locating...');
            this.$forceUpdate();
            const options = {
                enableHighAccuracy: true,
                timeout: (this.question.Timeout || 20) * 1000,
                maximumAge: 10

            };
            if (!q.Meta) q.Meta = [];
            this.startLocationWatch(q, options);

            // TODO: Apply timeout in options.
            /* let location = {};
            // if (Ui.isMac()) {
            location = await Ui.getLocation(options);
            // }
            // else {
            //     location = await Geolocation.getCurrentPosition(options);
            // }

            if (!q.Meta) q.Meta = [];
            const meta = q.Meta;
            const answer = q.Answer;
            const idx = q.loopCounter || 0;

            if (!meta[idx]) meta[idx] = {};
            meta[idx].ValueLat = parseFloat(location.coords.latitude.toFixed(7));
            this.$set(q, 'DisplayLat', meta[idx].ValueLat);
            meta[idx].ValueLon = parseFloat(location.coords.longitude.toFixed(7));
            this.$set(q, 'DisplayLon', meta[idx].ValueLon);
            meta[idx].ValueAcc = parseFloat(location.coords.accuracy.toFixed(2));
            this.$set(q, 'DisplayAcc', meta[idx].ValueAcc);
            meta[idx].ValueAccFail = location.coords.accuracy > q.Accuracy;
            this.$set(q, 'DisplayAccFail', meta[idx].ValueAccFail);
            meta[idx].ValueAlt = location.coords.altitude ? parseFloat(location.coords.altitude.toFixed(7)) : -1;
            this.$set(q, 'DisplayAlt', meta[idx].ValueAlt);
            meta[idx].ValueTime = location.timestamp;
            this.$set(q, 'DisplayTime', new Date(meta[idx].ValueTime).toISOString());
            // meta.ValueArr = [meta[idx].ValueLon, meta[idx].ValueLat]; // Mongo stores as long then lat.
            meta[idx].ValueDisplay = this.$format.dmsLatLong(meta[idx].ValueLat, meta[idx].ValueLon);
            this.$set(q, 'Display', meta[idx].ValueDisplay);
            this.$forceUpdate();

            // q.Accuracy
            // q.GPSSkip

            // answer[idx] = this.$format.dmsLatLong(meta[idx].ValueLat, meta[idx].ValueLon);
            answer[idx] = [meta[idx].ValueLon, meta[idx].ValueLat]; // Mongo stores as long then lat. */

            // q.Answer = q.Answer.slice();
            /* navigator.geolocation.getCurrentPosition(location => {
                // q.ValueLat = parseFloat(location.coords.latitude.toFixed(7));
                // q.ValueLon = parseFloat(location.coords.longitude.toFixed(7));
                // q.ValueAcc = location.coords.accuracy;
                // q.ValueAccFail = location.coords.accuracy > q.Accuracy;
                // q.ValueAlt = location.coords.altitude ? parseFloat(location.coords.altitude.toFixed(7)) : -1;
                // // q.ValueSpd
                // q.ValueArr = [q.ValueLon, q.ValueLat]; // Mongo stores ar long then lat.
                // this.$set(q, 'Answer', this.$format.dmsLatLong(q.ValueLat, q.ValueLon)); // Trigger change with $set.
                // Show a map?
                // mapLink.href = `https://www.openstreetmap.org/#map=18/${latitude}/${longitude}`;
                // mapLink.textContent = `Latitude: ${latitude} °, Longitude: ${longitude} °`;
                if (!q.Meta) q.Meta = [];
                const meta = q.Meta;
                const answer = q.Answer;
                const idx = q.loopCounter || 0;

                if (!meta[idx]) meta[idx] = {};
                meta[idx].ValueLat = parseFloat(location.coords.latitude.toFixed(7));
                this.$set(q, 'DisplayLat', meta[idx].ValueLat);
                meta[idx].ValueLon = parseFloat(location.coords.longitude.toFixed(7));
                this.$set(q, 'DisplayLon', meta[idx].ValueLon);
                meta[idx].ValueAcc = location.coords.accuracy;
                this.$set(q, 'DisplayAcc', meta[idx].ValueAcc);
                meta[idx].ValueAccFail = location.coords.accuracy > q.Accuracy;
                this.$set(q, 'DisplayAccFail', meta[idx].ValueAccFail);
                meta[idx].ValueAlt = location.coords.altitude ? parseFloat(location.coords.altitude.toFixed(7)) : -1;
                this.$set(q, 'DisplayAlt', meta[idx].ValueAlt);
                // meta.ValueArr = [meta[idx].ValueLon, meta[idx].ValueLat]; // Mongo stores as long then lat.
                meta[idx].ValueDisplay = this.$format.dmsLatLong(meta[idx].ValueLat, meta[idx].ValueLon);
                this.$set(q, 'Display', meta[idx].ValueDisplay);
                this.$forceUpdate();

                // answer[idx] = this.$format.dmsLatLong(meta[idx].ValueLat, meta[idx].ValueLon);
                answer[idx] = [meta[idx].ValueLon, meta[idx].ValueLat]; // Mongo stores as long then lat.
                // q.Answer = q.Answer.slice();
                // this.$set(q, 'Answer', answer); // Use .$set to trigger the change.
            }); */
        },
        startLocationWatch (q, options) {
            const meta = q.Meta;
            const answer = q.Answer;
            const idx = q.loopCounter || 0;
            let attempts = 0;
            if (!meta[idx]) meta[idx] = {};
            this.isLocationOn = true;
            this.bestLocation = {
                latitude: 0,
                longitude: 0,
                accuracy: 10000
            };
            this.locationWatchId = navigator.geolocation.watchPosition(location => {
                attempts += 1;
                this.$set(q, 'DisplayAttempts', attempts);
                if (location.coords.accuracy < this.bestLocation.accuracy) {
                    this.bestLocation = location.coords;
                    this.setLocation(location, q, meta, answer, idx);
                    if (this.bestLocation.accuracy <= (q.Accuracy || 10)) {
                        this.stopLocation();
                    }
                }
            }, err => {
                this.errorDetail = err.message;
                this.stopLocation();
                if (q.Display === 'Locating...') {
                    this.$set(q, 'Display', err.message);
                }
            }, options);
        },
        stopLocation () {
            this.isLocationOn = false;
            navigator.geolocation.clearWatch(this.locationWatchId);
        },
        setLocation (location, q, meta, answer, idx) {
            meta[idx].ValueLat = parseFloat(location.coords.latitude.toFixed(7));
            this.$set(q, 'DisplayLat', meta[idx].ValueLat);
            meta[idx].ValueLon = parseFloat(location.coords.longitude.toFixed(7));
            this.$set(q, 'DisplayLon', meta[idx].ValueLon);
            meta[idx].ValueAcc = parseFloat(location.coords.accuracy.toFixed(2));
            this.$set(q, 'DisplayAcc', meta[idx].ValueAcc);
            meta[idx].ValueAccFail = location.coords.accuracy > q.Accuracy;
            this.$set(q, 'DisplayAccFail', meta[idx].ValueAccFail);
            meta[idx].ValueAlt = location.coords.altitude ? parseFloat(location.coords.altitude.toFixed(7)) : 'n/a';
            this.$set(q, 'DisplayAlt', meta[idx].ValueAlt);
            meta[idx].ValueTime = location.timestamp;
            this.$set(q, 'DisplayTime', this.$format.dateShortTime(new Date(meta[idx].ValueTime)));
            // meta.ValueArr = [meta[idx].ValueLon, meta[idx].ValueLat]; // Mongo stores as long then lat.
            meta[idx].ValueDisplay = this.$format.dmsLatLong(meta[idx].ValueLat, meta[idx].ValueLon);
            this.$set(q, 'Display', meta[idx].ValueDisplay);
            this.$forceUpdate();

            // q.Accuracy
            // q.GPSSkip

            // answer[idx] = this.$format.dmsLatLong(meta[idx].ValueLat, meta[idx].ValueLon);
            answer[idx] = [meta[idx].ValueLon, meta[idx].ValueLat]; // Mongo stores as long then lat.
        },
        onPrevious () {
            if (this.isSingleQuestion) {
                this.coverMessageIndex--;
            }
            this.answerNotValid = false;
            this.errorDetail = '';
            if (!this.stepState.length) return;
            this.filterOne = '';
            this.filterMany = '';
            // Move.
            const step = this.stepState.pop();
            this.loopCounter = step.counter;
            this.index = step.index;
            // Lets scroll to top
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        },
        updateDriverScanner () {
            this.$refs.driverScanner.answerValue = '';
            this.$refs.driverScanner.tableData = [];
            this.$refs.driverScanner.displayQRValue = false;
        },
        updateBarcodeScanner () {
            this.$refs.barcodeScanner.answerValue = '';
        },
        onDynamicLookupSelection (items) {
            this.selectedDynamicValues = items;
            this.question.Answer[this.loopCounter || 0] = this.selectedDynamicValues;
        },
        onUserLookupSelection (users) {
            this.question.Answer[this.loopCounter || 0] = users;
        },
        async onNext () {
            if (this.question.InputType === Constants.INPUT_TYPE.Sketch &&
                this.sketchImageSrc !== '' &&
                !this.selectedMarker) {
                // Ensure the image is resized if the user didn't 'draw' anything, maining,
                // the 'newImageData' method wasn't fired so resizing wasn't done as yet.
                const sizer = new ImageResize(this.question.Width, this.question.Height, 1);
                const imgSized = await sizer.resize(this.sketchImageSrc);
                this.question.Answer[this.loopCounter || 0] = imgSized;
            }

            if (this.$refs.driverScanner !== undefined) {
                this.updateDriverScanner();
            }

            if (this.$refs.barcodeScanner !== undefined) {
                this.updateBarcodeScanner();
            }

            if (this.isLocationOn) {
                this.stopLocation();
            }

            if (this.index >= Constants.END_QUESTION) return true;

            let pass = this.runPassRules();
            if (pass && this.question.Field) {
                const hasError = this.questionErrors[this.question.Field];
                if (hasError) pass = false;
            }

            if (this.question.InputType === Constants.INPUT_TYPE.Note) {
                pass = true;
            }

            this.answerNotValid = (pass === false);
            if (this.isSingleQuestion && !this.answerNotValid) {
                this.coverMessageIndex++;
            }

            if (this.answerNotValid) {
                return false;
            }

            if (this.item.Rating && !(this.index === 0 && this.item.ShowStart)) {
                this.runRatingRules();
            }

            this.filterOne = '';
            this.filterMany = '';

            // Keep current index for moving backwards.
            const currentIndex = this.index;
            const currentCounter = this.loopCounter;
            if (!this.ShowEnd) {
                this.checkShowMessage = true;
            }

            // if (!this.item.ShowEnd && this.index >= Constants.END_QUESTION) return;
            const lastIndex = this.getLastQuestionIndex();
            let nextIndex = 0;
            if (pass === true) { // A boolean and not a number.
                if (this.index === lastIndex && this.item.ShowEnd) {
                    // At the end and can show end message.
                    nextIndex = Constants.END_QUESTION;
                }
                else {
                    // Get the next question and validate its SHOW rules.
                    nextIndex = this.getNextQuestionIndex(currentIndex);
                }
            }
            else {
                // else nextIndex = pass - 1; // NextId received from `runPassRules`. Display index therefore - 1.
                nextIndex = pass;
            }

            // Move.
            this.stepState.push({ index: currentIndex, counter: currentCounter });
            this.index = nextIndex;

            // Lets scroll to top
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

            if (this.index !== lastIndex && this.index !== Constants.END_QUESTION) return false;

            return true;
        },
        /**
         * Runs the pass rules for the current question.
         * Returns boolean or next question index.
         */
        runPassRules () {
            if (this.index === 0 || (this.item.ShowEnd && this.isEnd)) {
                return true; // Start and End messages.
            }

            let value = this.makeRuleValue(this.getAnswerValue(this.index));
            const q = this.question;
            let calcOptions = Constants.INPUT_TYPE;
            if (q.InputType === Constants.INPUT_TYPE.XorGrid ||
                q.InputType === Constants.INPUT_TYPE.DynamicLookup ||
                q.InputType === Constants.INPUT_TYPE.FileUpload) {
                value = Data.getAnswerValue(q, Constants.INPUT_TYPE);
            }

            if (q.InputType === Constants.INPUT_TYPE.Number) {
                if (value === '' || isNaN(value)) return false;
                if (q.Min !== undefined && q.Min !== '' && value < +q.Min) return false;
                if (q.Max !== undefined && q.Max !== '' && value > +q.Max) return false;
            }

            if (!q.Optional && this.isBlank(value)) {
                // Question is required therefore check that a value exists before continuing.
                return false;
            }

            if (q.InputType === Constants.INPUT_TYPE.SelectOne || q.InputType === Constants.INPUT_TYPE.SelectMany || q.InputType === Constants.INPUT_TYPE.SelectOneDD) {
                calcOptions = q.Options;
            }

            if (!q.PassRules.length) {
                return true; // No rules to run.
            }

            const len = q.PassRules.length;
            // Run each rule. Stop at first success.
            let isOk = false;
            for (let i = 0; i < len; i++) {
                const o = q.PassRules[i];
                // Replace @ with the current question value.
                const rule = o.Value.replace(/@/g, value === '' ? "''" : ((typeof value === 'string') ? '"' + value + '"' : value));
                const pass = Data.calc(rule, this.item.Questions, calcOptions);
                if (pass) {
                    // Rule passes, go to the Next index.
                    return o.NextId || true;
                }

                if (o.NextId) {
                    // Rule failed but is a jump check because it has a Next index. Let it pass.
                    isOk = true;
                }
                else {
                    // Rule failed.
                    isOk = false;
                }
            }
            // Rules are done. Even if validation failed, pass because of optional.
            return q.Optional ? true : isOk;
        },
        runRatingRules () {
            const value = this.makeRuleValue(this.getAnswerValue(this.index));
            const q = this.question;
            let calcOptions = Constants.INPUT_TYPE;
            if (q.InputType === Constants.INPUT_TYPE.SelectOne || q.InputType === Constants.INPUT_TYPE.SelectMany || q.InputType === Constants.INPUT_TYPE.SelectOneDD) {
                calcOptions = q.Options;
            }
            const len = q.RatingRules.length;
            if (!len) return true; // No rules to run.
            let ratingCal = 0;
            for (let i = 0; i < len; i++) {
                const o = q.RatingRules[i];
                // Replace @ with the current question value.
                const rule = o.Value.replace(/@/g, value === '' ? "''" : ((typeof value === 'string') ? '"' + value + '"' : value));
                const match = Data.calc(rule, this.item.Questions, calcOptions);
                if (match) {
                    ratingCal += o.Rating;
                    if (!q.RuleConstruction || q.RuleConstruction === undefined) break;
                }
            }
            q.Rating = ratingCal;
            this.sumRatings();
        },
        sumRatings () {
            let total = 0;
            for (const q of this.item.Questions) {
                if (typeof q.Rating === 'number') {
                    total += q.Rating;
                }
            }
            this.ratingTotal = total;

            // Update the varables in the questions as well as Start and End messages.
            if (this.item.ShowStart) {
                let def = this.defStartMsg;
                if (def.indexOf('{today}') > -1) def = def.replace(/{today}/g, this.$format.dateYMDDash(new Date()));
                if (def.indexOf('{rating-total}') > -1) def = def.replace(/{rating-total}/g, this.$format.number(this.ratingTotal));
                this.item.StartMessage = def;
            }
            if (this.item.ShowEnd) {
                let def = this.defEndMsg;
                if (def.indexOf('{today}') > -1) def = def.replace(/{today}/g, this.$format.dateYMDDash(new Date()));
                if (def.indexOf('{rating-total}') > -1) def = def.replace(/{rating-total}/g, this.$format.number(this.ratingTotal));
                this.item.EndMessage = def;
            }
        },
        /**
         * Converts a value to be used in the rule engine.
         */
        makeRuleValue (value) {
            // If value is an array, flatten and wrap in [].
            if (Array.isArray(value)) {
                const vt = [];
                const len = value.length;
                for (let j = 0; j < len; j++) {
                    if (Data.isString(value[j])) vt.push(`"${value[j]}"`);
                    else vt.push(value[j]);
                }
                value = `[${vt.join(',')}]`;
            }
            else if (Data.isDate(value)) {
                value = this.$format.dateYMD(value);
            }
            return value;
        },
        /**
         * Finds the next question index.
         * Runs the show rules on it.
         * Recursive.
         */
        getNextQuestionIndex (fromIndex) {
            const current = this.item.Questions.find(o => o.Index === fromIndex);
            if (!current) {
                return this.item.Questions[0].Index;
            }

            const pos = this.item.Questions.indexOf(current);
            const nextPos = pos + 1;
            let q = this.item.Questions[nextPos];
            if (this.loopCounter === 0 && q && q.LoopParentIndex !== undefined) {
                this.loopCounter = 1;
                this.loopTo = current.Answer[0];
            }
            else if (current && current.LoopParentIndex !== undefined) {
                // const parentAnswer = this.getAnswerValue(current.LoopParentIndex);
                const parent = this.item.Questions.find(o => o.Index === current.LoopParentIndex);
                // const parentAnswer = Data.getAnswerValue(parent, Constants.INPUT_TYPE);
                const parentAnswer = parent.Answer[0];
                this.loopTo = parentAnswer;
                if (fromIndex === parent.loopEndIndex) {
                    if (this.loopCounter < parentAnswer) {
                        this.loopCounter += 1; // Increment the loop counter.
                        q = this.item.Questions.find(x => x.Index === parent.loopStartIndex); // Go back to the loop start.
                    }
                    else {
                        this.loopCounter = 0;
                    }
                }
            }

            if (!q) {
                return Constants.END_QUESTION;
            }

            return q.Index; // All passed. Go to the next question.
        },
        runShowRules (q) {
            if (!q.ShowRules) return q.Index;
            const calcOptions = Constants.INPUT_TYPE;
            const len = q.ShowRules.length;
            let returnIndex = q.Index;
            if (!len) {
                return returnIndex; // No rules. Go to the next question.
            }
            // Run each rule. All must pass.
            let allPass = false;
            const calcResult = [];
            for (let i = 0; i < len; i++) {
                const o = q.ShowRules[i];
                const rule = o.Value;
                const pass = Data.calc(rule, this.item.Questions, calcOptions);
                calcResult.push(pass);
            }
            let outputString = 'return ';
            if (calcResult.length > 1) {
                for (let index = 0; index < calcResult.length; index++) {
                    const element = calcResult[index];
                    if (index < (calcResult.length - 1)) {
                        outputString += element + ' || ';
                    }
                    else outputString += element;
                }
            }
            else {
                outputString = outputString + calcResult[0] + ';';
            }
            // eslint-disable-next-line no-new-func
            allPass = new Function(outputString)();
            if (!allPass) {
                returnIndex = this.getNextQuestionIndex(q.Index); // Failure. Step forward to another valid/available question.
            }

            return returnIndex;
        },
        getAnswerValue (index) {
            const q = this.item.Questions.find(o => o.Index === index);
            if (!q) return null;
            return q.Answer[q.loopCounter || 0];
        },
        async onSave () {
            this.isSaving = true;
            this.$showProgress();
            const answer = {};

            try {
                // Run onNext logic, which runs PassRules to determine if a question jump is required or not
                const nextResult = await this.onNext();
                if (!nextResult) return;

                if (this.question.InputType === 22 && this.question.Answer[0] === '') {
                    throw new Error('You can not submit an empty object for the Driver QR Scanner.');
                }

                const fileUploads = [];
                for (const o of this.item.Questions) {
                    if (o.Answer !== undefined && typeof o.Answer === 'object') {
                        if (o.Answer[0] === '' && o.InputType === this.$CONST.INPUT_TYPE.XorGrid) {
                            o.Answer.shift();
                            o.Answer.push(o.GridData);
                        }
                    }

                    if (o.InputType === this.$CONST.INPUT_TYPE.FileUpload) {
                        if (!o.ValueFile) continue;
                        o.Answer = [];
                        o.Answer.push({});
                        fileUploads.push({ Question: o.Field, File: o.ValueFile });
                    }

                    let v;
                    if (o.LoopParentIndex) {
                        v = (o.Answer ? o.Answer.slice(1) : null);
                    }
                    else {
                        v = (o.Answer ? o.Answer[0] : null);
                    }

                    if (v !== null || v !== undefined || v) {
                        answer[o.Field] = v;
                    }
                }

                if (this.item.Rating) {
                    answer.Rating = this.ratingTotal;
                }

                if (this.preview) {
                    this.$info('Answers', `<pre>${JSON.stringify(answer, null, 4)}</pre>`, 20);
                    this.onBack();
                    return;
                }

                // Create a server compatible answer record.
                const data = {
                    ProjectId: (this.viewProject._id) ? this.viewProject._id : this.item.ProjectId,
                    SurveyId: this.item._id,
                    Version: this.item.Version,
                    Answer: answer,
                    StartDate: this.startDate,
                    EndDate: new Date(),
                    User: this.user._id,
                    IsExternal: this.isExternal,
                    TaskId: '',
                    MetaFields: [],
                    _SvrId: '', // Once pushed to the server, the server side record id.
                    _ACK: 0 // Once pushed to the server, this flag will be true. Local only. Not saved server side.
                };

                // lets add the metafields
                data.MetaFields = [];
                if (this.navcatreference !== '') {
                    data.NavLabel = this.navcatreference;
                }

                if (this.item.MetaFields) {
                    for (let index = 0; index < this.item.MetaFields.length; index++) {
                        const element = this.item.MetaFields[index];
                        data.MetaFields.push(element);
                    }
                }

                // Add a task id if it is available
                const taskId = this.$route.query.taskId;
                if (taskId) {
                    data.TaskId = taskId;
                }

                // if we need to emit the data we will not save it and just emit is
                if (!this.propEmitSave) {
                    // Store the answer locally first before trying to send it to the server.
                    const answerId = await this.$db.setAnswer(data, fileUploads);

                    for (const fileUpload of fileUploads) {
                        await this.$db.setAnswerUpload(answerId, fileUpload);
                    }
                    this.isSaveDone = true;
                    if (this.item.Exposure === 'I') { // Internal.
                        // Success message.
                        this.$success('Done', 'Your answer has been saved.');
                        // Send to App to action server push.
                        this.$root.$emit('SRVY_SubmitAnswers'); // THIS LINE WAS REMOVED AS THE DASHBOARD AUTO SYNCS AND CAUSES CHAOS
                        if (!this.propRouteOnSave) {
                            // Go to the dashboard.
                            let route = `/SurveyAct?id=${this.loadedId}&instance=${this.instance}`;

                            if (this.navcatreference !== '') {
                                route = `/SurveyAct?id=${this.loadedId}&instance=${this.instance}&navcat=${this.navcatreference}`;
                            }

                            this.$router.push({ path: route }).catch(err => {
                                // Check if error is for nav to same location, do nothing, otherwise throw the error.
                                if (err.name !== 'NavigationDuplicated') throw err;
                            });
                        }
                        else {
                            this.$emit('on-route');
                        }
                    }
                    else {
                        // Success message.
                        this.$success('Done', 'Thank you. Your answer has been saved.');
                        if (!this.propRouteOnSave) {
                            // Send to App to action server push.
                            this.$root.$emit('SRVY_SubmitExternalAnswers', { ProjectId: this.item.ProjectId });
                        }
                    }

                    if (this.isExternal) {
                        setTimeout(() => {
                            location.reload();
                        }, 2000);
                    }
                }
                else {
                    this.$emit('on-save', data, fileUploads);
                    this.isSaveDone = true;
                }
            }
            catch (ex) {
                this.$error(this.$t('general.data_failed'), ex.message ? ex.message : this.$t('general.an_error'));
            }
            finally {
                this.isSaving = false;
                this.$hideProgress();
            }
        },
        onBack () {
            this.$router.go(-1);
        },
        filterManyChange () {
            return this.optionsMany;
        },
        updateScannerValue (value) {
            this.question.Answer = [];
            this.question.Answer.push(value);
        },
        updateDriverValue (value) {
            this.question.Answer = [];
            this.question.Answer.push(value);
        },
        resyncExternalForms () {
            // Send to App to action server push.
            if (this.offlineDataAvailble && this.offlineProjId !== '') {
                this.$root.$emit('SRVY_SubmitExternalAnswers', { ProjectId: this.item.ProjectId });
                this.$success('Done', 'We have submitted a re-sync request');
            }
            else this.$error('Sync Error', 'Could not submit re-sync request');
            if (this.isExternal) {
                setTimeout(() => {
                    location.reload();
                }, 2000);
            }
        },
        cancelResync () {
            this.offlineDataAvailble = false;
        },
        hydrateUserLookupUsers (q) {
            if (!q.UserLookupOptions) return;
            this.isBusy = true;
            this.$showProgress();
            try {
                this.$http.get(`/Role/${encodeURI(q.UserLookupOptions.RoleId)}/users`)
                    .then(res => {
                        if (res.data.d) {
                            this.userLookupUsers = res.data.d;
                        }
                    });
            }
            catch (ex) {
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
            finally {
                this.isBusy = false;
                this.$hideProgress();
            }
        },
        // index watch methods
        handleDefaults (q) {
            if (q.Default !== undefined) {
                switch (q.InputType) {
                    case Constants.INPUT_TYPE.Date: {
                        const isToday = `${q.Default}`.toUpperCase().includes('TODAY');
                        if (isToday && q.Answer[q.loopCounter] === undefined) {
                            q.Answer[q.loopCounter] = this.$format.dateYMDDash(new Date());
                        }
                        if (!isToday && q.Answer[q.loopCounter] === undefined) {
                            q.Answer[q.loopCounter] = q.Default;
                        }
                        break;
                    }
                    case Constants.INPUT_TYPE.Time: {
                        const isTime = `${q.Default}`.toUpperCase().includes('NOW');
                        if (isTime && q.Answer[q.loopCounter] === undefined) {
                            q.Answer[q.loopCounter] = this.$format.dateShortTime(new Date().getTime());
                        }
                        if (!isTime && q.Answer[q.loopCounter] === undefined) {
                            q.Answer[q.loopCounter] = q.Default;
                        }
                        break;
                    }
                    case Constants.INPUT_TYPE.YesNo:
                    case Constants.INPUT_TYPE.SelectMany: {
                        if (q.Answer[q.loopCounter] === undefined) q.Answer[q.loopCounter] = q.Default;
                        break;
                    }
                }
            }
        },
        handleGps (q) {
            if (q.InputType !== Constants.INPUT_TYPE.GPSLocation) return;

            if (!q.Meta) q.Meta = [];
            const idx = q.loopCounter;
            if (!q.Meta[idx]) {
                q.Meta[idx] = {};
                q.Meta[idx].ValueLat = '';
                q.Meta[idx].ValueLon = '';
                q.Meta[idx].ValueAcc = '';
                q.Meta[idx].ValueAccFail = '';
                q.Meta[idx].ValueAlt = '';
                q.Meta[idx].ValueArr = '';
                // q.Meta[idx].ValueSpd = '';
            }
            if (!q.DisplayLat) {
                q.DisplayLat = '-';
                q.DisplayLon = '-';
                q.DisplayAcc = '-';
                q.DisplayAccFail = '-';
                q.DisplayAlt = '-';
                q.DisplayArr = '-';
                q.Display = '-';
            }
            else {
                if (!q.Meta[idx]) q.Meta[idx] = {};
                q.DisplayLat = q.Meta[idx].ValueLat || '-';
                q.DisplayLon = q.Meta[idx].ValueLon || '-';
                q.DisplayAcc = q.Meta[idx].ValueAcc || '-';
                q.DisplayAccFail = q.Meta[idx].ValueAccFail || '-';
                q.DisplayAlt = q.Meta[idx].ValueAlt || '-';
                q.Display = q.Meta[idx].ValueDisplay || '-';
            }
        },
        handleDefaultPlaceholders (q) {
            if (q.Default !== undefined && (q.Answer[q.loopCounter] === undefined || (q.Answer[q.loopCounter].toString().includes('{') && q.Answer[q.loopCounter].toString().includes('}')))) {
                // this.question.Answer[this.loopCounter || 0] = this.question.Default;
                let def = `${q.Default}`;

                if (def.indexOf('{today}') > -1) {
                    def = def.replace(/{today}/g, this.$format.dateYMDDash(new Date()));
                }

                if (def.indexOf('{now}') > -1) {
                    def = def.replace(/{now}/g, this.$format.dateShortTime(new Date()));
                }

                if (def.indexOf('{rating-total}') > -1) {
                    def = def.replace(/{rating-total}/g, this.$format.number(this.ratingTotal));
                }

                q.Answer[q.loopCounter] = def;
            }
        },
        handleImageSizes (q) {
            if (q.InputType === Constants.INPUT_TYPE.Image) {
                if (!q.Width && !q.Height) q.Width = 640;
                if (!q.Quality) q.Quality = 85;
            }

            if (q.InputType === Constants.INPUT_TYPE.Sketch) {
                if (!q.Width && !q.Height) q.Width = 640;
                if (!q.Quality) q.Quality = 85;
            }

            if (q.InputType === Constants.INPUT_TYPE.Signature) {
                if (q.Answer[q.loopCounter]) {
                    this.canSignAgain = true;
                }
                else {
                    this.initSignPad();
                }
            }
        },
        handleQuestionText (q) {
            this.questionText = q.Question[0].Value || '<p>[Question]</p>';
            if (this.questionText.indexOf('%index%') > -1 && q.LoopParentIndex !== undefined) { // Looping question.
                this.questionText = this.questionText.replace(/%index%/g, q.loopCounter);
            }
        },
        handleDynamicLookupHydrate (q) {
            this.selectedDynamicLookupSourceItems = [];
            this.selectedDynamicLookupType = { value: '', text: '' };

            if (q.InputType === Constants.INPUT_TYPE.DynamicLookup) {
                let keep = q.Answer[this.loopCounter || 0];
                if (!keep) {
                    keep = [];
                }
                this.selectedDynamicValues = keep;
                q.Answer[this.loopCounter || 0] = this.selectedDynamicValue;

                if (q.DynamicLookupOptions.SelectedSource && q.DynamicLookupOptions.SelectedSource.Value) {
                    const selectedSource = q.DynamicLookupOptions.SelectedSource;
                    this.selectedDynamicLookupSource = { value: selectedSource.Value, Text: selectedSource.Text };
                    this.$showProgress();
                    this.$db.getDynamicLookupSet(this.selectedDynamicLookupSource.value).then(sourceItems => {
                        this.selectedDynamicLookupSourceItems = sourceItems;
                        this.$hideProgress();
                    });
                }

                if (q.DynamicLookupOptions.SelectedType && q.DynamicLookupOptions.SelectedType.Value) {
                    const y = q.DynamicLookupOptions.SelectedType;
                    this.selectedDynamicLookupType = { value: y.Value, Text: y.Text };
                }

                if (!q.Answer[this.loopCounter || 0]) {
                    q.Answer[this.loopCounter || 0] = [];
                }
            }
        },
        handleUserLookupHydrate (q) {
            if (q.InputType === Constants.INPUT_TYPE.UserLookup) {
                this.hydrateUserLookupUsers(q);
            }
        },
        handleLastQuestion () {
            // this.isEnd = this.item.ShowEnd ? (this.item.Questions.length === 1 || this.index === Constants.END_QUESTION);
            if (this.item.Questions) {
                if (!this.isEnd) {
                    const indexId = this.runShowRules(this.question);
                    if (this.question.Index !== indexId) this.index = indexId;
                }

                const len = this.item.Questions.length;
                const lastIndex = this.getLastQuestionIndex();
                const indexes = [lastIndex, Constants.END_QUESTION];

                this.isEnd = this.item.ShowEnd
                    ? this.index === Constants.END_QUESTION
                    : (len === 1 || indexes.includes(this.index));
            }
        },
        getLastQuestionIndex () {
            const maxIndex = this.item.Questions.reduce((max, q) => {
                return Math.max(max, q.Index);
            }, -Infinity);

            return maxIndex;
        }
    },
    watch: {
        question: {
            deep: true,
            handler: function (newVal) {}
        },
        index () {
            const q = this.item.Questions.find(o => o.Index === this.index);
            if (q) {
                if (q.Answer === undefined) {
                    this.$set(q, 'Answer', []);
                }

                q.loopCounter = this.loopCounter || 0; // This is only used in `getAnswerValue`.

                // Defaults.
                this.handleDefaults(q);

                this.handleGps(q);

                this.handleDefaultPlaceholders(q);

                this.handleImageSizes(q);

                // TODO: Handle language selection.
                // TODO: Add previous answer value in question text, similar to rules e.g. {13}.
                this.handleQuestionText(q);

                this.handleDynamicLookupHydrate(q);

                this.handleUserLookupHydrate(q);

                // Set to be the current question.
                this.question = q;
            }
            else {
                this.question = {};
            }

            this.handleLastQuestion();
        },
        modalDOB (val) {
            if (val === true) {
                setTimeout(() => {
                    this.localActivePicker = 'YEAR';
                });
            }
        },
    },
    computed: {
        optionsOne () {
            if (this.question.Filter) {
                return this.question.Options.filter(o => o.Lang === this.lang && o.Value.toLocaleLowerCase().startsWith(this.filterOne.toLocaleLowerCase())).slice(0, 20);
            }
            else {
                return this.question.Options;
            }
        },
        optionsMany () {
            if (this.question.Filter) {
                return this.question.Options.filter(o => o.Lang === this.lang && o.Value.toLocaleLowerCase().startsWith(this.filterMany.toLocaleLowerCase())).slice(0, 20);
            }
            else {
                return this.question.Options;
            }
        },
        ratingVal: {
            get () {
                return parseInt(this.question.Answer[this.loopCounter || 0]);
            },
            set (val) {
                this.question.Answer[this.loopCounter || 0] = val;
            }
        },
        numberStep () {
            if (this.question && this.question.NumType === 'D') return 0.01;

            return 1;
        },
        ...mapState({
            viewProject: 'viewProject',
            user: 'user'
        }),
        isSingleQuestion () {
            return this.item.Questions.length === 1;
        }
    }
};
</script>
